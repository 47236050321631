import * as React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../components/seo"
// import { StaticImage } from "gatsby-plugin-image"

const AboutPage = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulAboutUsPage {
            edges{
                node{
                   paragraph1 {
                    childMarkdownRemark {
                        html
                      }
                   }
                   paragraph2 {
                    childMarkdownRemark {
                        html
                      }
                   }
                }
            }
        },
      }`)
    const aboutUsData = data.allContentfulAboutUsPage.edges
    const aboutUs = aboutUsData[0].node

    return (
        <Layout>
            <Seo
                title="About Us - Iron West Group Affordable Heavy Equipment Sales"
                description="Iron West Group offers the lowest prices for the sale of heavy equipment, construction equipment, commercial equipment, attachements, and much more."
                location={props.location.pathname}
                keywords={["Iron West Group Affordable Equipment For Sale"]}
            />
            <div className="bg-lightgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    {/* <div className="lg:grid lg:grid-cols-2"> This is replaced by the div below*/}
                    <div className="grid">
                        <div className="mx-auto py-4">
                            <h1 className="font-bold text-3xl pb-3 text-center lg:text-left">About Us</h1>
                            <hr className="border-orange max-w-xl mx-auto" />
                            {/* <div className="">
                                <div className="border rounded-xl sm:w-96 mx-auto md:w-96 h-fit shadow-2xl">
                                    <StaticImage src="../images/thumbnail.jpeg" placeholder="none" loading="eager" title="Iron West Group" alt="Iron West Group" />
                                </div>
                            </div> */}
                            {/* 
                            <div className="">
                                <div className="border rounded-xl sm:w-96 mx-auto md:w-96 h-fit shadow-2xl mt-5">
                                    <StaticImage src="../images/thumbnail.jpeg" placeholder="none" loading="eager" title="Iron West Group" alt="Iron West Group" />
                                </div>
                            </div> */}
                        </div>

                        <div className="px-6 leading-8 max-w-5xl mx-auto">
                            <div className="pt-4" dangerouslySetInnerHTML={{
                                __html: aboutUs.paragraph1.childMarkdownRemark.html,
                            }}>
                            </div>
                            <div className="pt-2" dangerouslySetInnerHTML={{
                                __html: aboutUs.paragraph2.childMarkdownRemark.html,
                            }}>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default AboutPage